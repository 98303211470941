.MyProjects .titulo{
    padding-left: 5%;
    color: rgb(var(--color-7));
    font-weight: 100;
}

.MyProjects .Carta{
    orientation: center;
    margin-top: 15px;
    background-color: rgba(var(--color-7), 0.5);
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 15px;
    text-align: start;
    
}

.MyProjects .Carta-imagen{
    border-radius: 15px;
    height: 167px !important;
    object-fit: scale-down;
}

.MyProjects .row{
    max-width: 100%;
}

.MyProjects p{
    font-weight: 400;
}

@media screen and (max-width: 810px){
    .MyProjects .justify-content-around-810{
        justify-content: space-around !important;
    }
}

@media screen and (max-width: 760px){
    .MyProjects .titulo{
        text-align: center;
        padding-left: 0px;
    }
}