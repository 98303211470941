.CreateProject {
    padding-bottom: 20px;
}

@media screen and (max-width:1500px) {
    .CreateProject {
        padding-bottom: 0px;
    }
}

.CreateProject .contenedor {
    margin-top: 20px;
    background-color: rgba(var(--color-20), 0.8);
    border-radius: 15px;
    padding: 20px;
    padding-left: 2%;
    max-width: 1500px;
    max-height: calc(100vh - (20vh + 30px + 48px + 20px));
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    color: rgb(var(--color-7));
}

.CreateProject .sub-titulo{
    color:  rgb(var(--color-1));
}

.CreateProject .titulo {
    padding-left: 5%;
    color: rgb(var(--color-7));
}

.CreateProject .iconSearch:hover {
    cursor: pointer;
    color:  rgb(var(--color-1));
}

.CreateProject .dropdown-item:active {
    background-color: rgb(var(--color-1));
    color: rgb(var(--color-22));
}

.CreateProject .dropdown-item:hover {
    background-color: rgb(var(--color-1));
    color: rgb(var(--color-22));
}

.CreateProject .dropdown-item:focus {
    border-color: rgb(var(--color-1));
}

.CreateProject .form-check-input:checked {
    background-color: rgb(var(--color-1));
    border-color: rgb(var(--color-1));
}

.CreateProject .svg-inline--fa {
    vertical-align: 0;
}

.CreateProject input {
    background-color: rgb(var(--color-7));
}

.CreateProject .dropdown-toggle {
    background-color: rgb(var(--color-7));
}

.CreateProject .dropdown-menu {

    background-color: rgb(var(--color-7));
}

.CreateProject .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgb(var(--color-7));
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.CreateProject .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.CreateProject .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.CreateProject .drop-area span {
    font-weight: 500;
}

.CreateProject .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.CreateProject .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.CreateProject .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.CreateProject .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.CreateProject .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-7), 0.4);
}

.CreateProject .card-file {
    position: relative;
}

.CreateProject .col-delete {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.CreateProject .col-delete:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}

.CreateProject .row-groups-and-list ul {
    /* border: 1px dotted pink; */
    margin: 0;
    list-style: none;
    padding: 5px;
    border-radius: 5px;

    display: inline-flex;
    flex-direction: column;
}

.CreateProject .row-groups-and-list li {
    color: rgb(var(--color-20));
    box-shadow: 0px 2px 4px rgb(var(--color-22));
    border-radius: 5px;
    padding: 0.3em 0.5em;
    background-color:  rgb(var(--color-7));
    text-align: center;
    margin: 5px;
    border: 2px solid transparent;
    top: auto !important;
    left: auto !important;
}

.CreateProject .row-groups-and-list li:focus {
    outline: none;
    border: 2px solid rgb(var(--color-22));
}

.CreateProject .row-groups {
    margin: 5px;
}

.CreateProject .row-groups .col-group {
    border: 2px solid  rgb(var(--color-7));
    margin: 5px;
    border-radius: 6px;
}

.CreateProject .row-groups h5 {
    margin: 5px;
}

.CreateProject .col-list {
    border: 2px solid  rgb(var(--color-7));
    margin: 10px;
    border-radius: 6px;
}

.CreateProject .col-list h3 {
    margin: 5px;
}

@media screen and (max-width: 519px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 98%;
        margin-right: 5px;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 100%;
        padding: 0px;
    }
}

@media screen and (min-width: 510px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 70%;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 100%;
        padding: 0px;
    }
}

@media screen and (min-width: 889px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 42%;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 55%;
    }
}

@media screen and (min-width: 1161px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 32%;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 60%;
    }
}

@media screen and (min-width: 1200px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 32%;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 60%;
    }
}

@media screen and (min-width: 1270px) {
    .CreateProject .col-list {
        flex: 0 0 auto;
        width: 30%;
    }

    .CreateProject .col-groups {
        flex: 0 0 auto;
        width: 62%;
    }
}