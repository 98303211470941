.MoodboardStudent .card-content {
    height: 1000px;
    width: 1371px;
    margin: 0px;
    border: 1px solid rgb(var(--color-7));
    border-radius: 5px;
}

.MoodboardStudent .card-icon {
    position: absolute;
    top: -9px;
    left: 1px;
    font-size: 15px
}

.MoodboardStudent .col-name {
    flex: 0 0 auto;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.MoodboardStudent .card-icon:hover {
    cursor: move;
}

.contenedor-Moodboard {
    background-color: rgba(65, 65, 65, 0.575);
    border-radius: 15px;
    padding: 28px;
}

.col-MoodboardStudentd {
    flex: 0 0 auto;
    width: 200px;
    margin: 10px;
}

.imagen {
    text-align: center;
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down;
    border-radius: 15px;
    
}

.MoodboardStudent .carta {
    padding: 5%;
    max-height: 100%;

}

.MoodboardStudent .card-properties {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.500);
    margin: 10px;
}

.MoodboardStudent .transform-none{
    transform: none !important;
}

.MoodboardStudent .cursor:hover{
    cursor: move;
}

.MoodboardStudent .no-cursor{
    cursor: auto;
}

.MoodboardStudent .card-edit{
    position: absolute;
    right: -8px;
    top: -11px;
}

.MoodboardStudent .card-edit:hover{
    cursor: pointer;
}

.MoodboardStudent .color-white{
    color: rgb(var(--color-22)) !important;
}

.MoodboardStudent .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgba(var(--color-22),0.5);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.MoodboardStudent .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.MoodboardStudent .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.MoodboardStudent .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.MoodboardStudent .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.MoodboardStudent .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.MoodboardStudent .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.MoodboardStudent .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-7), 0.4);
}

.MoodboardStudent .card-file {
    position: relative;
}

.MoodboardStudent .col-delete {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.MoodboardStudent .col-delete:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}

.MoodboardStudent .col-moodboard {
    flex: 0 0 auto;
    width: 200px;
    padding: 10px;
    margin: 10px;
    border: 2px solid rgb(var(--color-6));
    border-radius: 10px;
    text-align: center;
}

.MoodboardStudent .col-moodboard:hover {
    background-color: rgba(var(--color-22),0.5);
    cursor: pointer;
}

.MoodboardStudent .icon-file{
    position: absolute;
    top: -15px;
    right: 15px;
    color: rgb(var(--color-7)) !important;
    text-decoration: none !important;
}

.MoodboardStudent .icon-file a{
    color: rgb(var(--color-7)) !important;
    text-decoration: none !important;
}

.MoodboardStudent .icon-file-modal{
    position: absolute;
    color: rgb(var(--color-22)) !important;
    top: 0px;
    right: 0px;
}

.MoodboardStudent .icon-file-modal:hover{
    cursor: pointer;
    color: rgb(var(--color-24)) !important;
}

.MoodboardStudent .close-card{
    position: absolute;
    top: 0px;
    right: 10px;
}

.MoodboardStudent .close-card:hover{
    cursor: pointer;
    color: rgb(var(--color-24));
}

@media screen and (max-width: 544px) {

    .MoodboardStudent .row-center {
        display: flex;
        justify-content: center;
    }

}