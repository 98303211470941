:root {
    --color-1: 254, 200, 107;
    --color-2: 221, 179, 110;
    --color-3: 190, 159, 110;
    --color-4: 165, 143, 109;
    --color-5: 141, 128, 108;
    --color-6: 119, 114, 105;
    --color-7: 255, 255, 255;
    --color-8: 229, 228, 227;
    --color-9: 206, 204, 201;
    --color-10: 186, 185, 182;
    --color-11: 169, 166, 163;
    --color-12: 152, 150, 146;
    --color-13: 144, 141, 136;
    --color-14: 138, 134, 128;
    --color-15: 132, 127, 120;
    --color-16: 126, 121, 113;
    --color-17: 119, 114, 105;
    --color-18: 109, 106, 100;
    --color-19: 99, 97, 94;
    --color-20: 89, 89, 89;
    --color-21: 80, 82, 84;
    --color-22: 71, 75, 78;
    --color-23: 67, 207, 67;
    --color-24: 207, 67, 67;
}

@font-face {
    font-family: Roboto-Regular;
    src: url("./Assets/Font/Roboto-Regular.ttf");
}

*{
    font-family: Roboto-Regular , sans-serif;
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
    width:7px;
}

*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
    display: none;
} 

*::-webkit-scrollbar:horizontal {
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-22));
    border-radius: 10px;
    cursor: pointer !important;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgb(var(--color-22));
}

.row{
    --bs-gutter-x: 0;
}

.text-editor{
    line-height: 20px;
    padding-left: 25px !important;
}

.text-editor *{
    margin: 0px;
}

.text-editor h1,h2,h3,h4{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

body{
    color: rgb(var(--color-7));
    background-image: url("./Assets/Img/fondo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    max-height: 100vh;
}

@media screen and (max-width: 500px){
    body{
        background-position: 23.8% 0%;
    }
}

@media screen and (min-width: 500px){
    body{
        background-position: 27% 0%;
    }
}

@media screen and (min-width: 576px){
    body{
        background-position: 27.6% 0%;
    }
}

@media screen and (min-width: 768px){
    body{
        background-position: 28% 0%;
    }
}

@media screen and (min-width: 992px){
    body{
        background-position: 30% 0%;
    }
}

@media screen and (min-width: 1200px){
    body{
        background-position: 20% 0%;
    }
}

@media screen and (min-width: 1400px){
    body{
        background-position: 10% 0%;
    }
}