.AddFav{
    width: 28px;
    margin: 0px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
}
.AddFav .icon-false{
    color: rgba(128, 128, 128);
}
.AddFav .icon-false:hover {
    cursor: pointer;
    color: rgb(255, 220, 24);
}
.AddFav .icon-true{
    color: rgb(255, 220, 24);
}
.AddFav .icon-true:hover {
    cursor: pointer;
    color: rgba(128, 128, 128);
}