.CreateCourse .contenedor {
    margin-top: 20px;
    background-color: rgba(var(--color-20), 0.8);
    border-radius: 15px;
    padding: 20px;
    padding-left: 2%;
    max-width: 1500px;
    max-height: calc(100vh - (20vh + 30px + 48px + 20px));
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    color: rgb(var(--color-7));
}

.CreateCourse .contenedor h3{
    color: rgb(var(--color-1));
}

.CreateCourse .titulo {
    padding-left: 5%;
    color: rgb(var(--color-7));
}

.CreateCourse .iconSearch {
    color: rgb(var(--color-7));
}

.CreateCourse .iconSearch:hover {
    cursor: pointer;
    color: rgb(var(--color-1));
}

.CreateCourse .form-check-input:checked {
    background-color: rgb(var(--color-1));
    border-color: rgb(var(--color-1));
}

.CreateCourse .row-course-and-list ul {
    /* border: 1px dotted pink; */
    margin: 0;
    list-style: none;
    padding: 5px;
    border-radius: 5px;

    display: inline-flex;
    flex-direction: column;
}

.CreateCourse .row-course-and-list li {
    color: rgb(var(--color-20));
    box-shadow: 0px 2px 4px rgb(var(--color-22));
    border-radius: 5px;
    padding: 0.3em 0.5em;
    background-color: rgb(var(--color-7));
    text-align: center;
    margin: 5px;
    border: 2px solid transparent;
    position: relative !important;
    left: auto !important;
    top:  auto !important;

    
}

.CreateCourse .row-course-and-list .li {
    z-index: 4000 !important;
}

.CreateCourse .row-course-and-list li:focus {
    outline: none;
    border: 2px solid rgb(var(--color-22));
}

.CreateCourse .col-course {
    border: 2px solid rgb(var(--color-7));
    margin: 5px;
    border-radius: 6px;
}

.CreateCourse .col-course h4 {
    margin: 5px;
}

.CreateCourse .col-list {
    border: 2px solid rgb(var(--color-7));
    margin: 5px;
    border-radius: 6px;
}

.CreateCourse .col-list h4 {
    margin: 5px;
}

.CreateCourse .scroll-course{
    max-height: 600px;
    overflow-y: auto;
}

.CreateCourse .drop-area {
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgb(var(--color-7));
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 325px;
}

.CreateCourse .drop-area.active {
    width: 100%;
    border: 2px dashed  rgb(var(--color-1));
    background-color: rgba(var(--color-7), 0.1);
    color: rgb(var(--color-1));
}

.CreateCourse .drop-area:hover {
    border: 2px dashed  rgb(var(--color-1));
}

.CreateCourse .drop-area span {
    font-weight: 500;
}

.CreateCourse .mi-imagen-gris {
    -webkit-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
}

.CreateCourse .col-img {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 0 0 auto;
    width: 70px !important;
}

.CreateCourse .col-carga {
    width: 40px;
    position: absolute;
    top: 10px;
    left: 17px;
}

.CreateCourse .col-name {
    flex: 0 0 auto;
    max-width: 300px !important;
    height: 60px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 14px;
    font-size: 20px;
}

.CreateCourse .col-file {
    flex: 0 0 auto;
    width: 400px !important;
    margin: 5px;
    border-radius: 3px;
    background-color: rgba(var(--color-7), 0.4);
}

.CreateCourse .card-file {
    position: relative;
}

.CreateCourse .col-delete {
    font-size: 25px;
    position: absolute;
    width: 25px;
    padding: 0px;
    top: 5px;
    right: 5px;
}

.CreateCourse .col-delete:hover {
    color: rgb(var(--color-24));
    cursor: pointer;
}