.Chat .chat-open {
    position: fixed;
    width: 30px;
    height: 100px;
    bottom: calc((50vh - 50px));
    background-color: rgb(var(--color-6));
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 5;
}

.Chat .chat-open .icon {
    font-size: 25px;
    transform: rotateY(200deg);
    padding: 3px;
    margin-top: 11px;
    color: rgb(var(--color-1));
}

.Chat .chat-open:hover {
    cursor: pointer;
}

.Chat .list-users {
    background-color: rgb(var(--color-6));
    opacity: 90%;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 300px;
    height: 100vh;
    z-index: 5;
    max-height: 100vh;
    overflow-y: auto;
}

.Chat .user {
    position: fixed;
    background-color: rgb(var(--color-1));
    width: 230px;
    height: 35px;
    bottom: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 5;
}

.Chat .user-status {
    color: rgb(var(--color-23));
    flex: 0 0 auto;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chat .user-name {
    display: flex;
    align-items: center;
}

.Chat .user-name p {
    color: rgb(var(--color-22));
    font-weight: 800;
    margin: 0px;
}

.Chat .user-open {
    color: rgb(var(--color-22));
    font-weight: 800;
    flex: 0 0 auto;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chat .user-open-icon:hover {
    color: rgb(var(--color-5));
    cursor: pointer;
}

.Chat .list-user:hover {
    cursor: pointer;
    background-color: rgb(var(--color-1));
}

.Chat .chat{
    position: fixed;
    background-color: rgb(var(--color-8));
    width: 230px;
    height: 300px;
    bottom: 25px;
    z-index: 5;
}

.Chat .list-menssages{
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
    max-height: 260px;
    overflow-y: auto;
    z-index: 5;
    
}

.Chat .message{
    display: flex;
    margin: 10px;
    z-index: 5;
}

.Chat .message p{
    border-radius: 5px;
    padding: 5px;
    max-width: 250px;
    color: rgb(var(--color-7));
    z-index: 5;
    position: relative;
}

.chat .message .status{
    position: absolute;
    font-size: 13px;
    padding: 3px;
    bottom: 0px;
    right: 0px;
    color: rgb(var(--color-18));
}

.Chat .name-image-upload{
    z-index: 5000;
    position: absolute;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    top: -28px;
    left: 0px;
    height: 25px;
    max-width: 180px;
    font-size: 10px;
    white-space: nowrap;
    overflow-x: auto;
    background-color: rgb(var(--color-7));
    border-radius: 2px;
    color: rgb(var(--color-22));
}