.Project .nav-link {
    padding: 0px !important;
}

.Project .activate{
    color: rgb(var(--color-1)) !important;
}

.Project .titulo {
    color: rgb(var(--color-7));
    font-size: 1.8rem;
    text-decoration: none;
    margin-bottom: 0px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.Project .navbar-collapse .titulo {
    background-color: rgba(var(--color-8), 0.2);
}

.Project .navbar-toggler-icon {

    background-image: url("../../Assets/Img/toggler.svg")
}

.Project .navbar-light .navbar-toggler {
    border-color: rgb(var(--color-7));
}

.Project .project-outlet {
    padding: 20px;
    background-color: rgba(var(--color-20), 0.8);
    margin-top: 15px;
    margin-left: auto;
    margin-right: 6vw;
    border-radius: 10px;
    height: calc(100vh - (25px + 5px + 15px + 20vh + 59px));
    max-width: 1500px;
    max-height: 680px;
    overflow-y: auto;
    max-height: calc(650px);
}

.Project .container-info .title {
    color: rgb(var(--color-1));
    font-size: 1.8rem;
    margin-bottom: 0px;
}

.Project .container-info {
    background-color: rgba(var(--color-11), 1);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width: 991px) {

    .Project .navbar-collapse .titulo {
        background-color: rgba(var(--color-22),0.9);
    }

    .Project .navbar-collapse{
        z-index: 4;
    }

    .Project .navbar-collapse .top{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    
    .Project .navbar-collapse .bottom{
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

}

@media screen and (max-height: 610px) {
    .Project .project-outlet{
        max-height: calc(100vh - (25px + 5px + 15px + 123px + 59px ));
    }

}

@media screen and (max-height: 610px) {

    .Project .project-outlet {
        height: calc(100vh - (25px + 5px + 15px + 123px + 59px));
    }

}

@media screen and (max-width: 1705px) {

    .Project .project-outlet {
        margin-right: 3vw;
        
    }

}

@media screen and (max-width: 1595px) {

    .Project .project-outlet {
        margin-right: auto;
    }

}

@media screen and (max-width: 1569px) {

    .Project .project-outlet {
        margin-right: 35px;
        margin-left: 35px;
    }

}

@media screen and (max-width: 760px) {

    .Project .project-outlet {
        margin-right: 0px;
        margin-left: 0px;
    }

}